<template>
  <div class="newsWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="newsBar mb20" :style="{backgroundImage: 'url('+newsBg+')'}">
          <div class="newsMain">
            <div class="title mb20"><img :src="title" alt="新闻中心"></div>
            <div class="tagWrap">
              <span class="tag" v-for="(item,index) in tagList" :class="{active:tagActive==index}" @click="tagChange(item,index)">{{item.title}}</span>
            </div>
            <div class="newCons">
              <div class="item" v-for="(item,index) in NewnewsList" @click="goNewsDetail(item)">
                <div class="title">{{item.title}}</div>
                <div class="time">{{item.pubDate}}</div>
              </div>
            </div>
            <div class="pageWrap">
              <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :current-page="page"
                :page-size="size"
                :total="total"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from 'axios'
  export default {
    name: 'news',
    components:{
      menuWX,
      downItemWX,
    },
    data(){
      return {
        newsBg:require('@/assets/imgs/news/newsBg.png'),
        title:require('@/assets/imgs/news/title.png'),
        tagActive:0,
        tagList:[
          {catId:0,title:'全部'},
          {catId:1,title:'新闻'},
          {catId:2,title:'活动'},
          {catId:3,title:'公告'},
        ],
        newsList:[],
        NewnewsList:[],
        page:1,
        size:20,
        total:100,
      }
    },
    created() {
      this.getData()
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      getData(){        // 获取初始数据

        let data = {
          catId: this.tagList[this.tagActive].catId,
          types:'lists',
        }
        axios.post('/news/getNewslist', data).then((res) => {
          if(res.data.state == 200){
            this.total = res.data.data.counts
            this.newsList = res.data.data.list
            this.NewnewsList = this.newsList.slice(0,this.size)
            

          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      goNewsDetail(item){       // 跳转到新闻详情
        this.$router.push({
          path:'/newsDetail',
          query:{
            newsId:item.newsId,
          }
        })
      },
      tagChange(item,index){      // 切换TAG
        this.tagActive = index
        this.page = 1
        this.getData()
      },
      handleCurrentChange(val){
        this.NewnewsList = this.newsList.slice((val-1)*this.size,val*this.size)
        this.page = val
        // this.getData()
      },
    }
  }
</script>

<style lang="less" scoped>
  .newsWrap{
    .newsBar{
      padding:40px 40px 50px 40px;
      height: 1000px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      .newsMain{
        .title{
          text-align: center;
        }
        .tagWrap{
          margin: 0 63px 10px;
          padding-bottom:5px;
          display: flex;
          justify-content: space-around;
          border-bottom: 2px solid #999999;
          .tag{
            font-size: 26px;
            font-weight: 400;
            color: #444444;
            line-height: 30px;
            cursor: pointer;
          }
          .active{
            font-weight: 700;
          }
        }
        .newCons{
          margin-bottom: 65px;
          height: 640px;
          box-sizing: border-box;
          overflow-y: auto;
          .item{
            margin: 0 63px 4px;
            padding: 16px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #999999;
            &:hover{
              color: #D22190;
              cursor: pointer;
            }
            .title{
              font-size: 20px;
            }
            .time{
              font-size: 20px;
            }
          }
        }
        .pageWrap{
          text-align: center;
        }
      }
    }
  }
</style>
